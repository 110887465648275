body{
  overflow-x: hidden;
  background-color: #000
}

.admin-panel{
  margin-top: 150px;
}

.admin-panel, .add-form{
  max-width: 850px;
  border-radius: 20px;
  background-color: #303030;
  padding: 30px;  
}

.searched-error{
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
}

.error-is-recent{
  background-color: rgb(206, 34, 11);
}

.error-not-recent{
  background-color: rgb(204, 137, 12);
}

.admin-buttons{
  margin: 10px auto;
  font-size: 1.5em;
  padding: 15px;
}

.add-form{
  width: 100%;
  margin: 0 auto;
  margin-top: 150px;
}

.add-error-button{
  margin: 5px;
  padding: 10px;
  font-size: 1.25em;
}

.recent-errors-display td{
  padding: 25px 0px;
}

.recent-errors-display{
  color: #fff;
  font-size: 1.25em;
  background-color: #303030
}

.table-header th{
  font-size: 1.5em;
  padding-top: 10px;
  padding-bottom: 40px;
  font-family: Arial;
}