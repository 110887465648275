.sidebar {
  background-color: #0a3c54;
  /* display: flex;
  flex-flow: column;
  height: 100%; */
}

.sidebar a {
  text-decoration: none;
  color: white;
}

.links div {
    padding: 20px 0;
    font-size: 22px;
    font-weight: bold;
}
