.app-header{
    background-color: #303030;
    color: #FFF;
  }
  
  .app-output{
    text-align: center;
    color: #FFF;
  }
  
  .app-output h4{
    font-size: 1.5em;
    line-height: 25px;
  }
  
  .app-display{
    max-width: 800px;
    margin-top: 20px;
    background-color: #303030;
    padding: 20px;
    border-radius: 25px;
  }
  
  .num-shots{
    font-size: 1.5em;
  }
  
  .app-button{
    background-color: #FF9900;
    padding: 5px;
    color: #FFF;
    font-size: 15pt;
    text-transform: uppercase;
  }
  
  hr{
    border:1px solid grey;
  }