.modal_bg {
  background-color: grey;
}

.items_btn:hover {
  cursor: pointer;
}

.discontinuedButton {
  height: 36px;
  border: 2px solid black;
  padding-top: 4px;
  color: black;
  border-radius: 10px;
  cursor: pointer;
}

.discontinuedModal {
  background-color: #303030;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  height: 500px;
  border-radius: 10px;
  border: 5px solid rgb(233, 217, 0);
  z-index: 100;
}

.modalBackground {
  background-color: rgba(0, 0, 0, 0.658);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
}

.discontinuedConfirm {
  background-color: rgb(255, 238, 0);
  width: 100px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  margin: auto;
}

.discontinuedCancel {
  background-color: rgb(17, 204, 0);
  width: 100px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  margin: auto;
}
