body{
    background-color: black;
}
.App {
    text-align: center;
    color: white;
}

.card{
    background-color: black;
}

.card-lg{
    color:white;
    background-color: #383838;
    border-radius: 5px;;
}

.barcodeOptions button{
    width: 75%;
    border-radius: 5px;
    padding:10px;
}

.card h2{
    padding:5px;
    border-radius: 10px;
    color:white;
    background-color: #383838;
}

.items p,button{
    background-color: white;
    color:black;
    border:2px solid black;
    border-radius: 10px;
}
.sku{
    font-size: 0.67em;
    color:#383838;
}

.bookerImage {
    text-align: center;
  }
  
  .bookerImage h1{
    color: #fff;
    font-family: 'Be Vietnam Pro', sans-serif;
    font-size: 2.5em;
  }
  
  .bookerImage p{
    font-family: 'Be Vietnam Pro', sans-serif;
    color: #fff;
    font-size: 1.2em;
  }
  
  .bookerImage input{
    background-color: #f2f2f2;
    width: 250px;
    height: 25px;
    border: 2px solid #707070;
    border-radius: 5px;
    color: #333;
  }
  
  .bookerImage .imagePrint {
    margin-top: 2%;
  }
  .bookerImage .buttons {
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: space-between;
  }
  .bookerImage button {
      background-color: #383838;
      color: #fff;
      padding: 8px;
      border-radius: 20px;
      /* margin-left: 2%; */
  }

  .bookerImage a {
    color: #fff;
}

  .bookerImage button:hover {
    background-color: #707070;
}

/* #buttonClick {
  display: none;
} */