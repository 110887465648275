h4 {
    color: #fff;
    text-align: center;
    font-size: 1.5em;
}

.message {
    width: 20%;
    text-align: center;
    color: black;
    font-size: 20px;
    position: relative;
    left: 41%;
    font-family: sans-serif;
    text-transform: capitalize;
    margin-top: -38%;
}

button{
    margin-top: 2%;
}

.cardImg{
    position: relative;
    margin-top: 36%;
    left: 24%;
    z-index: -1;
}

.printCover{
    width: 100%;
    height: 900px;
    background-color: black;
    border-top: 5px solid #f5e17a;
}