.form{
    background-color: #0a3c54;
    color:white
}

.details{
    background-color: white;
    color:black;
}

.submissionArea{
    background-color: #2c7ca4
}