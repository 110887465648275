.qlinn{
    background-color: black;
    height: 38px;
    width: 120px;
    background-image: url("../../../Images/qlinnButton.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    cursor: pointer;
}

.woo{
    background-color: #7f54b3;
    height: 38px;
    width: 120px;
    background-image: url("../../../Images/wooButton.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    cursor: pointer;
}

.shop{
    background-color: #00cc55;
    border:1px solid green;
    height: 38px;
    width: 120px;
    background-image: url("../../../Images/shopifyButton.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    cursor: pointer;
}

.reason:hover{
    background: #d7dde4 !important;
}