.wrapper {
  background-color: white;
  margin: auto;
}

.boxOuter {
  background-color: #2c7ca4;
}

.boxInner {
  /* background-color: white; */
  color: white;
}

.emails {
  background-color: white;
  color: black;
}

.reasons {
  background-color: white;
  color: black;
}


.add {
  background-color: #22bb00;
  display: inline-block;
  cursor: pointer;
}
