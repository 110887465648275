.wrapperInner {
  background: #2c7ca4;
  color: white;
}

.wrapperOutter {
  background: white;
}

.refunds {
  background: white;
  color: black;
}

.refundButton {
  background: #ff4040;
  color: white;
  cursor: pointer;
  width: 75%;
  margin: auto;
}

.reasonBox {
  width: 80%;
  margin: auto;
  background: white;
  cursor: pointer;
}

.messageBox {
  position: absolute;
  top: 3px;
  left: 100%;
  background-color: #0a3c54;
  color: white;
  width: 115px;
  font-size: 10px;
  height: 35px;
}

.confirmButton {
  background: #eeff55;
  width: 50%;
  margin: auto;
  cursor: pointer;
}

.actionedBy {
  background: white;
  color: black;
  height: 150px;
}
